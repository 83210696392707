<template>
	<router-view />

	<!-- <tk-button class="tech-button" type="button" @click="tech">
		Обратиться в тех. поддержку
	</tk-button> -->
</template>

<script>
export default {
	methods: {
		tech() {
			this.$router.push({ path: '/profile/feedback' })
		}
	}
}
</script>

<style lang="sass" scoped>
.tech-button
	margin-top: 30px
	max-width: 250px
	height: 40px
@media screen and (max-width: 800px)
	.tech-button
		margin: 30px auto 0 auto
</style>

<style lang="sass">
.helpcenter
	position: relative
	display: flex
	flex-direction: column
	.wrap
		display: flex
		justify-content: space-between

	.list-help
		padding: 0

		list-style: none

		font-style: normal
		font-weight: normal
		font-size: 16px
		line-height: 30px
		color: #3B3F47

	&__buttons
		display: flex
	&__tab
		display: flex
		justify-content: center
		align-items: center
		width: 200px
		height: 40px
		padding: 0 10px
		transition: 0.1s linear
		border: 1px solid #1AC386
		color: #1AC386
		background: #fff
		font-weight: 600
		font-size: 14px
		line-height: 14px
		border-radius: 12px
		cursor: pointer
		&.active,
		&:hover
			color: #fff
			background: #1AC386
		input
			display: none
		&:first-child
			margin-right: 20px
	&__title
		font-weight: bold
		font-size: 24px
		line-height: 24px
		margin-bottom: 30px
	&__form
		width: 420px
		position: relative
		&:before
			content: url('~@/assets/images/svg/magnifying-glass.svg')
			position: absolute
			top: 15px
			left: 15px
		&__input
			width: 100%
			height: 55px
			padding: 15px 15px 15px 45px
			background: #FFFFFF
			border: 1px solid #CBCBCB
			border-radius: 12px
			font-size: 12px
			line-height: 12px
			margin-bottom: 30px
			caret-color: #1AC386
			&::-webkit-search-cancel-button
				-webkit-appearance: none
				background: url('~@/assets/images/svg/close-gray.svg') center no-repeat
				width: 20px
				height: 20px
	&__link
		position: relative
		display: flex
		align-items: center
		height: 55px
		width: 420px
		padding-right: 25px
		padding-left: 15px
		background: #FFFFFF
		color: #3B3F47
		font-size: 14px
		line-height: 12px
		text-decoration: none
		border-radius: 12px
		box-shadow: 0px 0px 17px -6px lightgrey
		margin-bottom: 10px
		transition: 0.1s linear
		&:before
			content: url('~@/assets/images/svg/arrow-right.svg')
			position: absolute
			right: 15px
		&.helpcenter__link_recomendations
			margin-bottom: 30px
		&:hover
			background: #1AC386
			color: #ffffff
			&.helpcenter__link::before
				content: url('~@/assets/images/svg/arrow-right-white.svg')
	&__button
		&__problem
			display: flex
			justify-content: center
			align-items: center
			background: #5F5350
			padding: 0 50px
			border: 1px solid #5F5350
			border-radius: 12px
			width: 420px
			height: 40px
			transition: 0.1s linear
			&:hover
				background: #ffffff
				color: #5F5350

// 1080
@media screen and (max-width: 1440px)
	.helpcenter
		&__buttons
			button
				width: 160px

// 768
@media screen and (max-width: 912px)
	.helpcenter
		.wrap
			flex-direction: column
		&__buttons
			margin-bottom: 30px
		&__form
			width: 100%
		&__link
			width: 100%

// 540
@media screen and (max-width: 800px)
	.helpcenter
		&__buttons
			display: flex
			button
				width: 150px
		&__button
			&__problem
				width: 100%
			&__trip
				margin-right: 15px

.question-answer
	position: relative
	width: 530px
	&__title
		font-weight: bold
		font-size: 24px
		line-height: 24px
		margin-bottom: 30px
	&__form
		width: 420px
		position: relative
		&:before
			content: url('~@/assets/images/svg/magnifying-glass.svg')
			position: absolute
			top: 15px
			left: 15px
		&__input
			width: 100%
			height: 55px
			padding: 15px 15px 15px 45px
			background: #FFFFFF
			border: 1px solid #CBCBCB
			border-radius: 12px
			font-size: 12px
			line-height: 12px
			margin-bottom: 30px
			caret-color: #1AC386
			&::-webkit-search-cancel-button
				-webkit-appearance: none
				background: url('~@/assets/images/svg/close-gray.svg') center no-repeat
				width: 20px
				height: 20px
	&__accordion
		max-width: 420px
		padding: 0
		margin: 0
		margin-bottom: 10px
		list-style: none
		input
			display: none
			&:checked
				&~article
					transition: height 0.2s linear
					height: 150px
					visibility: visible
				&+label
					&:before
						transform: rotate(180deg)
						transition: transform 0.2s linear
		&:hover
			&.question-answer__accordion label
				background: #1AC386
				color: #ffffff
				&::before
					content: url('~@/assets/images/svg/arrow-down-white.svg')
		label
			position: relative
			z-index: 2
			display: flex
			align-items: center
			width: 100%
			height: 55px
			padding: 15px 25px 15px 15px
			box-shadow: 0px 0px 17px -6px lightgrey
			border-radius: 12px
			font-size: 14px
			line-height: 12px
			cursor: pointer
			&:before
				content: url('~@/assets/images/svg/arrow-down.svg')
				position: absolute
				transition: transform 0.2s linear
				right: 15px
		article
			position: relative
			z-index: 1
			height: 0px
			padding: 5px 15px
			padding-top: 20px
			margin-top: -5px
			font-size: 14px
			line-height: 18px
			visibility: hidden
			overflow-y: auto
			transition: height 0.2s linear
			border-radius: 12px
			background: #fff
			box-shadow: 0px 10px 12px rgba(95, 83, 80, 0.1), 0px 10px 15px rgba(0, 0, 0, 0.1)

// 540
@media screen and (max-width: 800px)
  .question-answer
    width: 100%
    &__form
      width: 100%
    &__accordion
      max-width: 100%
</style>
